import React from 'react'
import { navigate } from 'gatsby'

import AuthService from 'services/auth'

import Spinner from 'components/common/Spinner'

export default function Callback({ location }) {
	// AuthService.handleAuthentication()

	React.useEffect(() => {
		if (/access_token|id_token|error/.test(location.hash)) {
			AuthService.handleAuthentication()
		}

		navigate('/app')
	}, [])

	return <Spinner />
}
